<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      mode="subscription"
      :pk="publishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="(v) => (loading = v)"
    />
    <v-btn @click="submit" color="primary">Pay</v-btn>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
export default {
  components: {
    StripeCheckout,
  },
  data() {
    this.publishableKey =
      "pk_test_51KYufDEV5PWIaZOvzGge2ko7huetBDE8ZDeiS96IHjG7nLsJzDYrUfXHGTMJaRYMGzBa6JUAZPPVZ1RV19McIj1y00EM0PAtXB";
    return {
      loading: false,
      lineItems: [
        {
          price: "price_1KmMiOEV5PWIaZOvTvPmkscH", // The id of the recurring price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: window.origin + "/success",
      cancelURL: window.origin + "/cancel",
    };
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>